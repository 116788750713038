
// form label has a width in base.
.iris-form-group {
    .iris-form-group__label {
        float: none;
        padding-top: 0;
        width: auto;
    }
}

.iris-textfield .iris-textfield__input {
        border: 1px solid #707070;
        border-radius: 3px;
        color: #333333;
        font-size: 1.4rem;
        padding: 10px;
        width: 100%;
}

// There is a base margin: 0 rule that is applied to most elements in the application.
// In order for flex to properly space things, we need to revert margins to auto.
.iris-notification__message {
    margin: auto 0;
}

// make baguettes the same width as #main until we remove that restriction on #main
.iris-notification--baguette .iris-notification__inner {
    max-width: 1005px !important;
}

// Reset the 5px bottom margin coming from bootstrap.
label {
    margin-bottom: auto; // IE
    margin-bottom: initial;
}

// labels have a float left in base
.iris-radio,
.iris-checkbox {
    label {
        color: inherit;
        float: none;
        width: auto;
        margin: 0;
        padding: 0;

        &.required:after {
            content: '';
        }
    }
}

// Reset Margin and Float on all inputs in iris components.
[class*="iris-"][type] {
    margin-top: 0;
    margin-bottom: 0;
    float: none;
}
